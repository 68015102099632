<template>
    <div class="invited-users">
        <ConfirmDialog ref="confirmDialog"/>

        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'SettingEmployees' }">Employees</router-link>
            </CBreadcrumbItem>
            <CBreadcrumbItem active> Invited Employees</CBreadcrumbItem>
        </teleport>

        <CCard>
            <CCardHeader>
                <Navigation :items="tabsLink"/>
            </CCardHeader>

            <CCardBody>
                <CRow class="gx-0 gx-lg-3 justify-content-between w-100 mb-4">
                    <CCol class="GCFilterMaxWidth col-12">
                        <GCFilter component="InvitedEmployees" @search="(val) => fetchFilter(val)"/>
                    </CCol>
                </CRow>

                <SmartTable :items="users" :items-per-page="parseInt(perPage)" :columns="fields" :sorter-value="sort"
                            :loading="loadingTable" :active-page="activePage" :per-page="perPage" :pages="pages"
                            :clear-text=" search.length ? 'No search results found' : 'No Data yet'"
                            @sorter-change="onSort" @update-active-page="(value) => (activePage = value)"
                            @update-per-page="(value) => (perPage = value)">
                    <template v-if="!search?.length" #clear_text>
                        <div class="table__clear d-flex align-items-center justify-content-center mb-2 w-100">
                            <CIcon class="table__clear__icon" size="6xl" name="cilUser"/>
                            <div class="table__clear__title">No Users yet</div>
                            <div class="table__clear__title-sub">
                                All users you have invited will be displayed here.
                            </div>
                            <CButton color="primary" class="table__clear__btn" @click="toggleInviteEmployeeModal">
                                Add user
                            </CButton>
                        </div>
                    </template>

                    <template #invited_user.first_name="{ item, column }">
                        <span v-if="column.key === 'invited_user.first_name'">{{ item.invited_user.first_name }}</span>
                    </template>
                    <template #invited_user.last_name="{ item, column }">
                        <span v-if="column.key === 'invited_user.last_name'">{{ item.invited_user.last_name }}</span>
                    </template>
                    <template #invited_user.role.show_name="{ item, column }">
                        <span v-if="column.key === 'invited_user.role.show_name'">
                            {{ item.invited_user.role.show_name }}
                        </span>
                    </template>
                    <template #invited_user.email="{ item, column }">
                        <span v-if="column.key === 'invited_user.email'">{{ item.invited_user.email }}</span>
                    </template>
                    <template #status="{ item, column }">
                        <span v-if="column.key === 'status'" class="text-capitalize"
                              :class="getTableItemStatusClass(item.status)">
                            {{ item.status }}
                        </span>
                    </template>

                    <template #button="{ item }">
                        <td>
                            <div class="invited-users__table-buttons">
                                <button v-if="item.status !== 'accepted'" type="button" :disabled="disabledListActions"
                                        class="invited-users__table-button invited-users__table-button--blue"
                                        :class="{ 'invited-users__table-button--disabled': disabledListActions }"
                                        @click="resendInvite(item)">
                                    <CIcon class="invited-users__table-button-icon" name="cil-reload"/>
                                    <span class="invited-users__table-button-text">Resend invite</span>
                                </button>

                                <button v-if="item.status === 'pending'" type="button" :disabled="disabledListActions"
                                        class="invited-users__table-button invited-users__table-button--red"
                                        :class="{ 'invited-users__table-button--disabled': disabledListActions }"
                                        @click="openCancelModal(item)">
                                    <CIcon class="invited-users__table-button-icon" name="cil-x"/>
                                    <span class="invited-users__table-button-text">Cancel invite</span>
                                </button>
                            </div>
                        </td>
                    </template>
                </SmartTable>
            </CCardBody>
        </CCard>
        <router-view/>
    </div>
    <InviteEmployeeModal :visible="inviteEmployeeModal" @close="toggleInviteEmployeeModal"/>
</template>

<script>
import table from '@/mixin/table'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import Navigation from '@/components/Card/Navigation'
import GCFilter from '@/components/GCFilter/index'
import debounce from 'lodash/debounce'
import InviteEmployeeModal from "@/components/Modals/InviteEmployeeModal.vue";
import UserInvitations from "@/api/v2/endpoints/UserInvitations";
import {mapGetters} from "vuex";
import apiErrorHandler from "@/mixin/apiErrorHandler";

export default {
    name: 'InvitedEmployees',
    components: {Navigation, ConfirmDialog, GCFilter, InviteEmployeeModal},
    mixins: [table, apiErrorHandler],
    inject: ['toast'],
    data() {
        return {
            mountedComponent: false,
            inviteEmployeeModal: false,
            fields: [
                {key: 'invited_user.first_name', label: 'First Name'},
                {key: 'invited_user.last_name', label: 'Last Name'},
                {key: 'invited_user.role.show_name', label: 'Role'},
                {key: 'invited_user.email', label: 'Email', email: true},
                {key: 'status', label: 'Status'},
                {
                    key: 'created_at',
                    label: 'Invite Date',
                    format: (value) =>
                        this.$TCurrentZone(value, true).toFormat('LL/dd/yyyy HH:mm'),
                },
                {
                    key: 'expires_at',
                    label: 'Link Expires in',
                    format: (value) =>
                        this.$TCurrentZone(value, true).toFormat('LL/dd/yyyy HH:mm'),
                },
            ],
            users: [],
            loadingTable: true,
            disabledListActions: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
        tabsLink() {
            return [
                {
                    name: 'Employees',
                    route: {
                        name: 'SettingCompanyUsersIndex',
                    },
                },
                {
                    name: 'Invites',
                    route: {
                        name: 'InvitedEmployees',
                    },
                },
            ]
        },
    },
    async mounted() {
        this.fetchData()
        this.setUpWebsocketListening();

        await this.$nextTick(() => {
            this.mountedComponent = true;
        });
    },
    methods: {
        fetchFilter: debounce(function (filter) {
            this.fetchData(filter)
        }, 400),
        fetchData(filter = {}) {
            this.toggleLoadingTable();

            UserInvitations
                .index(this.fetchDataParams(filter))
                .then(response => this.users = response.data)
                .finally(() => this.loadingTable = false);
        },
        fetchDataParams(filter = {}) {
            return {
                paginate: 1,
                per_page: this.perPage,
                page: this.activePage,
                order_by: this.sort && this.sort.column,
                order_dir: this.sort && this.sort.state,
                status: filter?.status || [],
                search: filter?.search || '',
                with_invited_user: 'first_name,last_name,email',
                with_user_role: 'show_name'
            };
        },
        resendInvite(userInvitation) {
            UserInvitations
                .resend(userInvitation.id)
                .then(() => this.toast('info', 'Invitation successfully resent!'))
                .catch(response => this.handleApiError(response))
        },
        cancelInvite(userInvitation) {
            UserInvitations
                .cancel(userInvitation.id)
                .then(response => {
                    this.toast('info', response.message)
                    this.fetchData()
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleDisableListActions())
        },
        openCancelModal(userInvitation) {
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to cancel the invite to <br/> ${userInvitation.invited_user.full_name}?`,
                    confirmText: 'Yes',
                    cancelText: 'No',
                    reverse: true,
                })
                .then(response => {
                    if (response) {
                        this.cancelInvite(userInvitation);
                        this.toggleDisableListActions();
                    }
                });
        },
        setUpWebsocketListening() {
            if (this.user) {
                this.$LaravelEcho
                    .private('user-notification.' + this.user.id)
                    .listen('.user-notification.user-invitation.created', () => this.fetchData())
                    .listen('.user-notification.user-invitation.resent', () => this.fetchData());
            }
        },
        getTableItemStatusClass(status) {
            if (status === 'pending') {
                return 'invited-users__status--blue';
            }

            if (status === 'accepted') {
                return 'invited-users__status--green';
            }

            return 'invited-users__status--red';
        },
        toggleDisableListActions() {
            this.disabledListActions = !this.disabledListActions;
        },
        toggleInviteEmployeeModal() {
            this.inviteEmployeeModal = !this.inviteEmployeeModal;
        },
        toggleLoadingTable() {
            this.loadingTable = !this.loadingTable;
        }
    },
}
</script>

<style lang="scss" scoped>
.invited-users {
    &__status {
        &--red {
            color: #E55353;
        }

        &--green {
            color: #2EB85C;
        }

        &--blue {
            color: #005D9D;
        }
    }

    &__table-buttons {
        display: flex;
        align-items: center;
        margin: 0 10px;
    }

    &__table-button {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0 10px;
        white-space: nowrap;

        &--blue {
            color: #005d9d;
        }

        &--red {
            color: #e55353;
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.table__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 60px;
    background: #f5f7f9;

    &__icon {
        color: #b1b7c1;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        color: #303c54;

        &-sub {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            color: #465967;
        }
    }
}
</style>
